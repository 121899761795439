<template>
  <Form ref="formRef" layout="vertical" :model="formData" :rules="getFormRules" class="mt-[32px]">
    <FormItem prop="email" class="enter-x">
      <Input
        v-model.trim="formData.email"
        :label="$t('siteBuild.header.email')"
        :style="mainButtonColor"
        size="large"
        :clearable="true"
        autocomplete="new-password"
        :placeholder="$t('siteBuild.header.email')"
      />
    </FormItem>
    <FormItem prop="password" class="enter-x">
      <InputPassword
        v-model.trim="formData.password"
        :style="mainButtonColor"
        size="large"
        name="register-pass"
        type="password"
        :clearable="true"
        autocomplete="new-password"
        :placeholder="$t('siteBuild.header.password')"
      />
    </FormItem>
    <FormItem>
      <Button
        type="primary"
        :class="!isMobile ? 'w-full h-[56px]  mt-[16px]' : 'w-full h-[48px]'"
        size="large"
        block
        :style="mainButtonColor"
        :normal="true"
        :loading="loading || loginLoading"
        :disabled="registerBtnDisabled"
        @click="handleRegister"
      >{{ $t('siteBuild.header.registerButton') }}</Button>
    </FormItem>
  </Form>
</template>
<script>
import colorMixin from './colorMixins'
import mobileMixin from './mobileMixins'
import { useFormRules, useLoginState, useFormValid } from "./useLogin";
import { Form, FormItem, Input, } from '~/site/components/forms/index' // Checkbox,
import Button from '~/site/components/forms/button'

export default {
  name: "RegisterForm",
  components: {
    Input,
    InputPassword: Input,
    Form,
    FormItem,
    Button,
  },
  mixins: [colorMixin, mobileMixin],
  props: {
    loginLoading: {
      type: Boolean,
      default: false,
    },
    siteId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: '',
      required: true
    },

    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { registerFormRules } = useFormRules(this.formData, this);
    const { handleBackLogin } = useLoginState()
    return {
      formData: {
        email: '', // 543803983@qq.com
        password: '', // Qw
      },
      loading: false,
      handleBackLogin,
      sendMailSuccess: false,
      getFormRules: registerFormRules
    }
  },
  computed: {
    sendCodeBtnDisabled() {
      return !(this.formData.email)
    },
    registerBtnDisabled() {
      if (this.editing) {
        return false
      }
      const { email, password, } = this.formData //  confirm, code
      return !(email && password) // && confirm && code
    }
  },
  created() {
  },
  methods: {
    handlerBack() {
      this.handleBackLogin()
    },

    /**
     * 商城用户注册
     */
    handleRegister() {
      if (this.editing) {
        return
      }
      const { validForm } = useFormValid(this.$refs.formRef)
      const { email, password } = this.formData
      this.$logger.captureMessage(email + '注册校验')
      validForm().then((data) => {
        this.loading = true
        this.$logger.captureMessage(email + '注册校验成功,注册中')
        this.$api.siteBuild.registerUser({
          email,
          site_id: this.siteId,
          kop_customer: {
            email,
            password
          }
        }).then(() => {
          this.$logger.captureMessage(email + '注册成功')
          this.$emit('login', { ...this.formData })
        }).catch((res) => {
          this.sendMailSuccess = false
          this.$logger.captureMessage(email + '注册失败', res)
          this.$emit('show-alert', res, 'error')
        }).finally(() => {
          this.loading = false
        })
      });
    }
  }
}
</script>

<style lang="less" scoped>
</style>
