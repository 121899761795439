<template>
  <div class="rounded-sm item-wrapper bg-fillColor-2">
    <div class="flex items-center justify-between w-full p-3 item-wrapper__header text-textColor-3">
      <div class="relative flex items-center title">
        <div class="circle" />
        <span>{{ title }}</span>
      </div>
      <im-icon v-if="showRemove" icon="icon-shanchu" class="cursor-pointer hover:text-white"
        @click.native="handleRemove" />
    </div>
    <slot>
      <div v-for="item in form" :key="item.prop" class="p-3 item-wrapper__form">
        <div class="item">
          <div class="label">{{ item.label }}</div>
          <!-- 此处可扩展其他组件 -->
          <imInput v-if="item.type === 'input'" v-model="model.name" class="mt-3" :class="`item-wrapper__${item.type}`"
            size="small" />
          <imPageInput v-if="item.type === 'pageInput'" v-model="model.url" class="mt-3"
            :class="`item-wrapper__${item.type}`" :suggest-list="item.list || []" size="small" @input="urlChange" />
          <im-select v-if="item.type === 'goodInput'" v-model="model.good" class="mt-3" :placeholder="''"
            :class="`item-wrapper__${item.type}`" size="small" :options="item.list" label-key="name" filterable
            @change="goodChange">
          </im-select>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import imInput from '~/components/im30/input'
import imPageInput from "~/components/im30/searchInput";

export default {
  components: {
    imInput,
    imPageInput
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    form: {
      type: Array,
      default() {
        return []
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    showRemove: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleRemove() {
      this.$emit('remove')
    },

    urlChange() {
      this.$set(this.model, 'good', '')
    },

    goodChange() {
      this.$set(this.model, 'url', '')
      console.log('goodChange', this.model.url)
    }
  }
}
</script>

<style scoped lang="less">
.item-wrapper {
  &__header {
    .title {
      .circle {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: @primary-color;
        margin-right: 5px;
      }
    }
  }

  &__input {
    color: white;
  }
}
</style>
