<template>
  <div class="dlocal-wrap">
    <main>
        <div class="container-lg">
            <section class="row example example-2">
                <div id="loader-example-2" class="loader"></div>
                <div id="fields-form-example-2">
                    <fieldset>
                        <div class="container">
                            <div id="customContainer" class="exampleX custom">
                                <div class="containerX">
                                    <div class="field newPayment">
                                        <label class="radiocontainer">New Payment Card
                                            <input type="radio" checked="checked" name="radio">
                                            <span class="radiomark"></span>
                                        </label>
                                        <img id="visa" alt="visa" class="brand-img visa first" src="./img/visa.svg" />
                                        <img id="mastercard" alt="mastercard" class="brand-img mastercard" src="./img/master.svg" />
                                        <img id="amex" alt="amex" class="brand-img amex" src="./img/amex.svg" />
                                        <img id="discover" alt="discover" class="brand-img discover" src="./img/discover.svg" />
                                    </div>
                                    <hr>
                                    <div id="fieldPanContainer" class="field half fieldFloating">
                                        <div id="containerPan" class="input empty" @click="clickPan()"></div>
                                        <label for="containerPan" @click="clickPan()">Card Number</label>
                                        <div class="errorMsgContainer">
                                            <span id="panErrorMsg" class="helpError">Enter your credit card number.</span>
                                        </div>
                                    </div>
                                    <div id="fieldExpirationContainer" class="field cuarter fieldFloating">
                                        <div id="containerExpiration" class="input empty" @click="clickExpiration()"></div>
                                        <label for="containerExpiration" @click="clickExpiration()">MM/YY</label>
                                        <div class="errorMsgContainer">
                                            <span id="expirationErrorMsg" class="helpError">Enter your credit card expiration.</span>
                                        </div>
                                    </div>
                                    <div id="fieldCVVContainer" class="field cuarter fieldFloating">
                                        <div id="containerCVV" class="input empty" @click="clickCVV()"></div>
                                        <label for="containerCVV" @click="clickCVV()">CVV</label>
                                        <div class="errorMsgContainer">
                                            <span id="cvvErrorMsg" class="helpError">Enter your credit card CVV.</span>
                                        </div>
                                        <div class="tooltipContainer">
                                            <img id="cvvTooltip" alt="cvv" class="tooltipImage" src="./img/question.svg" />
                                            <div class="tooltipInner">
                                                <img class="tooltipInnerImage" src="./img/security_code.svg">
                                            </div>
                                        </div>

                                    </div>
                                    <div id="nameContainer" class="field fieldFloating">

                                        <input id="name" type="text" placeholder="John Doe" required class="input empty" autocomplete="cc-name" />
                                        <label for="name">Cardholder Name</label>
                                        <div class="errorMsgContainer">
                                            <span class="helpError">Enter your name exactly as it appears on your card.</span>
                                        </div>
                                    </div>
                                    <div id="countryContainer" class="field half fieldFloating">
                                        <select id="country" required class="input empty">
                                            <option value="brazil">Brazil</option>
                                        </select>
                                        <label for="country">Country</label>
                                        <div class="errorMsgContainer">
                                            <span class="helpError">Select a country.</span>
                                        </div>
                                    </div>
                                    <div id="cpf_cnpjContainer" class="field half fieldFloating">
                                        <input id="cpf_cnpj" type="text" placeholder="158.611.782-32" class="input empty" required/>
                                        <label for="cpf_cnpj">CPF / CNPJ</label>
                                        <div class="errorMsgContainer">
                                            <span class="helpError">Enter your CPF / CNPJ.</span>
                                        </div>
                                    </div>
                                    <div class="field fieldCheck">
                                        <label class="radiocontainer">Remember this card
                                            <input id="remember" type="checkbox" checked="checked" name="radio">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <button @click="submit()">Complete Payment</button>
                                    <div class="terms">
                                        <label>By completing your purchase, you agree to these
                                            <a href="#" target="_blank">Terms of Service</a>.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div class="error" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                            <path class="base" fill="#000" d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"></path>
                            <path class="glyph" fill="#FFF" d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"></path>
                        </svg>
                        <span class="message"></span>
                    </div>
                </div>
                <div class="success">
                    <div class="icon">
                        <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <circle class="border" cx="42" cy="42" r="40" stroke-linecap="round" stroke-width="4" stroke="#000" fill="none"></circle>
                            <path class="checkmark" stroke-linecap="round" stroke-linejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338"
                                stroke-width="4" stroke="#000" fill="none"></path>
                        </svg>
                    </div>
                    <h3 id="example-2.success.title" class="title">Payment successful</h3>
                    <p class="message">
                        <span id="example-2.success.message">Thanks for trying Smart Fields. No money was charged, but we generated a token: </span>
                        <span class="token">token</span>
                    </p>
                    <a class="reset" href="#">
                        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path fill="#000000" d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"></path>
                        </svg>
                    </a>
                </div>
            </section>
        </div>
    </main>
  </div>
</template>

<script>

export default {
  name: "Dlocal",
  data() {
    return {
        api_key: 'efba0f53-252d-4da2-806c-3cb694a1e2d8',
        dlocalInstance: null,
        today: null,
        month: null,
        monthStr: null,
        year: null,
        fieldsExample: null,
        countryContainer: null,
        country: null,
        nameContainer: null,
        name: null,
        cpf_cnpj: null,
        cpf_cnpjContainer: null,
        panField: null,
        expirationField: null,
        cvvField: null,
        isPanEmpty: true,
        panHasError: false,
        isExpirationEmpty: true,
        expirationHasError: false,
        isCvvEmpty: true,
        cvvHasError: false,

    }
  },
  head: {
    script: [
      { src: 'https://js-sandbox.dlocal.com' },
    ]
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
        // eslint-disable-next-line
        this.dlocalInstance = dlocal(this.api_key)

        const that = this;

        this.today = new Date();
        this.month = this.today.getMonth() + 1
        this.monthStr = this.month <= 9 ? "0" + this.month : this.month.toString();
        this.year = (this.today.getFullYear() + 2).toString().substring(2);

        this.fieldsExample = this.dlocalInstance.fields({
            fonts: [{
                cssSrc: 'https://rsms.me/inter/inter-ui.css'
            }],
            locale: 'en',
            country: 'BR'
        });
        // config fields

        this.panField = this.fieldsExample.create('pan', {
            style: {
                base: {
                    fontSize: "15px",
                    fontFamily: "'Open Sans', sans-serif",
                    lineHeight: '30px',
                    fontSmoothing: 'antialiased',
                    fontWeight: '500',
                    iconColor: "#fff",
                    '::placeholder': {
                        color: "#fff"
                    }
                },
                focus: {
                    iconColor: "#adbfd3",
                    '::placeholder': {
                        color: "#adbfd3"
                    }
                }
            },
            placeholder: "4111 1111 1111 1111",
            hideIcon: true
        });

        this.cvvField = this.fieldsExample.create('cvv', {
            style: {
                base: {
                    fontSize: "15px",
                    fontFamily: "'Open Sans', sans-serif",
                    lineHeight: '30px',
                    fontSmoothing: 'antialiased',
                    fontWeight: '500',
                    '::placeholder': {
                        color: "white"
                    }
                },
                focus: {
                    '::placeholder': {
                        color: "#adbfd3"
                    }
                }
            },
            placeholder: "123"
        });

        this.expirationField = this.fieldsExample.create('expiration', {
            style: {
                base: {
                    fontSize: "15px",
                    fontFamily: "'Open Sans', sans-serif",
                    lineHeight: '30px',
                    fontSmoothing: 'antialiased',
                    fontWeight: '500',
                    '::placeholder': {
                        color: "white"
                    }
                },
                focus: {
                    '::placeholder': {
                        color: "#adbfd3"
                    }
                }
            },
            placeholder: this.monthStr + "/" + this.year
        });

        // mount fields

        this.panField.mount(document.getElementById('containerPan'));
        this.expirationField.mount(document.getElementById('containerExpiration'));
        this.cvvField.mount(document.getElementById('containerCVV'));

        // onReady

        let isPanReady = false;
        this.panField.on('ready', function (event) {
            isPanReady = true;
            if (isPanReady && isExpirationReady && isCVVReady) {
                that.loader(false);
            }
        });

        let isExpirationReady = false;
        this.expirationField.on('ready', function (event) {
            isExpirationReady = true;
            if (isPanReady && isExpirationReady && isCVVReady) {
                that.loader(false);
            }
        });

        let isCVVReady = false;
        this.cvvField.on('ready', function (event) {
            isCVVReady = true;
            if (isPanReady && isExpirationReady && isCVVReady) {
                that.loader(false);
            }
        });



        // chek other elements of the form
        this.name = document.getElementById('name')
        this.nameContainer = document.getElementById('nameContainer')

        this.country = document.getElementById('country')
        this.countryContainer = document.getElementById('countryContainer')

        this.cpf_cnpj = document.getElementById('cpf_cnpj')
        this.cpf_cnpjContainer = document.getElementById('cpf_cnpjContainer')

        // onChange
        this.name.addEventListener("keyup", function () {
            that.checkRequired(that.name, that.nameContainer)
        })

        this.country.addEventListener("onchange", function () {
            that.checkRequired(that.country, that.countryContainer)
        })


        this.cpf_cnpj.addEventListener("keyup", function () {
            that.checkRequired(that.cpf_cnpj, that.cpf_cnpjContainer)
        })

        // onAutofilled

        const onAnimationStart = event => {
            switch (event.animationName) {
                case 'onAutoFillStart':
                    document.getElementById('nameContainer').classList.add("autofilled");
                    break;
                case 'onAutoFillCancel':
                    document.getElementById('nameContainer').classList.remove("autofilled");
                    break;
            }
        };
        this.name.addEventListener('animationstart', onAnimationStart);


        // onBlur
        // eslint-disable-next-line
        this.isPanEmpty = true;
        // eslint-disable-next-line
        this.panHasError = false;
        this.panField.on('blur', function (event) {

            that.isPanEmpty = event.empty;
            if (event.error) {
                that.panHasError = true;
                document.getElementById('fieldPanContainer').classList.add("Field--required");
                document.getElementById('fieldPanContainer').classList.add("hasError");
                const error = event.error.message ? event.error.message : "Enter your credit card number."
                document.getElementById('panErrorMsg').innerHTML = error
            } else {
                that.panHasError = false;
                document.getElementById('fieldPanContainer').classList.remove("Field--required");
                document.getElementById('fieldPanContainer').classList.remove("hasError");
            }

            document.getElementById('containerPan').classList.remove("focus");
        })
        // eslint-disable-next-line
        this.isExpirationEmpty = true;
        // eslint-disable-next-line
        this.expirationHasError = false;
        this.expirationField.on('blur', function (event) {
            that.isExpirationEmpty = event.empty;
            if (event.error) {
                that.expirationHasError = true;
                document.getElementById('fieldExpirationContainer').classList.add("Field--required");
                document.getElementById('fieldExpirationContainer').classList.add("hasError");
                const error = event.error.message ? event.error.message : "Enter your credit card expiration."
                document.getElementById('expirationErrorMsg').innerHTML = error
            } else {
                that.expirationHasError = false;
                document.getElementById('fieldExpirationContainer').classList.remove("Field--required");
                document.getElementById('fieldExpirationContainer').classList.remove("hasError");
            }
            document.getElementById('containerExpiration').classList.remove("focus");
        })
        // eslint-disable-next-line
        this.isCvvEmpty = true;
        // eslint-disable-next-line
        this.cvvHasError = false;
        this.cvvField.on('blur', function (event) {
            that.isCvvEmpty = event.empty;
            if (event.error) {
                that.cvvHasError = true;
                document.getElementById('fieldCVVContainer').classList.add("Field--required");
                document.getElementById('fieldCVVContainer').classList.add("hasError");
                const error = event.error.message ? event.error.message : "Enter your credit card CVV."
                document.getElementById('cvvErrorMsg').innerHTML = error
            } else {
                that.cvvHasError = false;
                document.getElementById('fieldCVVContainer').classList.remove("Field--required");
                document.getElementById('fieldCVVContainer').classList.remove("hasError");
            }
            document.getElementById('containerCVV').classList.remove("focus");
        })

        // onFocus

        this.panField.on('focus', function () {
            document.getElementById('containerPan').classList.add("focus");
        })

        this.expirationField.on('focus', function () {
            document.getElementById('containerExpiration').classList.add("focus");
        })

        this.cvvField.on('focus', function () {
            document.getElementById('containerCVV').classList.add("focus");
        })

        // onAutofilled

        this.panField.on('autofilled', function (event) {
            if (event.autofilled) {
                document.getElementById('fieldPanContainer').classList.add("autofilled");
            } else {
                document.getElementById('fieldPanContainer').classList.remove("autofilled");
            }
        })

        this.expirationField.on('autofilled', function (event) {
            if (event.autofilled) {
                document.getElementById('fieldExpirationContainer').classList.add("autofilled");
            } else {
                document.getElementById('fieldExpirationContainer').classList.remove("autofilled");
            }
        })

        this.cvvField.on('autofilled', function (event) {
            if (event.autofilled) {
                document.getElementById('fieldCVVContainer').classList.add("autofilled");
            } else {
                document.getElementById('fieldCVVContainer').classList.remove("autofilled");
            }
        })

        // onChange

        this.panField.on('change', function (event) {

            if (!event.error) {
                this.panHasError = false;
                document.getElementById('fieldPanContainer').classList.remove("hasError");
                document.getElementById('fieldPanContainer').classList.remove("Field--required");
            } else {
                this.panHasError = true;
                document.getElementById('fieldPanContainer').classList.add("hasError");
                document.getElementById('fieldPanContainer').classList.add("Field--required");
                const error = event.error.message ? event.error.message : "Enter your credit card number."
                document.getElementById('panErrorMsg').innerHTML = error
            }
            this.isPanEmpty = event.empty;
            if (event.empty) {
                document.getElementById('containerPan').classList.add("empty");
            } else {
                document.getElementById('containerPan').classList.remove("empty");
            }
        })

        this.expirationField.on('change', function (event) {
            if (!event.error) {
                this.expirationHasError = false;
                document.getElementById('fieldExpirationContainer').classList.remove("Field--required");
                document.getElementById('fieldExpirationContainer').classList.remove("hasError");
            } else {
                this.expirationHasError = true;
                document.getElementById('fieldExpirationContainer').classList.add("Field--required");
                document.getElementById('fieldExpirationContainer').classList.add("hasError");
                const error = event.error.message ? event.error.message : "Enter your credit card expiration."
                document.getElementById('expirationErrorMsg').innerHTML = error
            }
            this.isExpirationEmpty = event.empty;
            if (event.empty) {
                document.getElementById('containerExpiration').classList.add("empty");
            } else {
                document.getElementById('containerExpiration').classList.remove("empty");
            }
        })


        this.cvvField.on('change', function (event) {
            if (!event.error) {
                this.cvvHasError = false;
                document.getElementById('fieldCVVContainer').classList.remove("Field--required");
                document.getElementById('fieldCVVContainer').classList.remove("hasError");
            } else {
                this.cvvHasError = true;
                document.getElementById('fieldCVVContainer').classList.add("Field--required");
                document.getElementById('fieldCVVContainer').classList.add("hasError");
                const error = event.error.message ? event.error.message : "Enter your credit card CVV."
                document.getElementById('cvvErrorMsg').innerHTML = error
            }
            this.isCvvEmpty = event.empty;
            if (event.empty) {
                document.getElementById('containerCVV').classList.add("empty");
            } else {
                document.getElementById('containerCVV').classList.remove("empty");
            }
        })

        // onBrand

        this.panField.on('brand', function (event) {
            const visa = document.getElementById('visa')
            const master = document.getElementById('mastercard')
            const amex = document.getElementById('amex')
            const discover = document.getElementById('discover')
            switch (event.brand) {
                case "visa":
                    showOnlyFlag(visa, master, amex, discover);
                    break;
                case "mastercard":
                    showOnlyFlag(master, visa, amex, discover);
                    break;
                case "american-express":
                    showOnlyFlag(amex, visa, master, discover);
                    break;
                case "discover":
                    showOnlyFlag(discover, visa, master, amex);
                    break;
                default:
                    visa.classList.add("first");
                    master.classList.remove("first");
                    amex.classList.remove("first");
                    discover.classList.remove("first");
                    visa.style.display = "block"
                    master.style.display = "block"
                    amex.style.display = "block"
                    discover.style.display = "block"
            }
        })

        function showOnlyFlag(show, hide1, hide2, hide3) {
            show.classList.add("first");
            hide1.classList.remove("first");
            hide2.classList.remove("first");
            hide3.classList.remove("first");
            show.style.display = "block"
            hide1.style.display = "none"
            hide2.style.display = "none"
            hide3.style.display = "none"
        }




        const fields = [this.panField, this.expirationField, this.cvvField]
        const inputs = [this.name, this.country, this.cpf_cnpj]
        const formClass = '.example-2'
        const example = document.querySelector(formClass);

        const resetButton = example.querySelector('a.reset');
        // let error = form.querySelector('.error');

        resetButton.addEventListener('click', function (e) {
            e.preventDefault();

            inputs.forEach(function (input) {
                input.value = ''
            });

            // Clear each Smart-Field.
            fields.forEach(function (field) {
                field.clear();
            });

            document.getElementById('fieldCVVContainer').classList.remove("autofilled");
            document.getElementById('fieldExpirationContainer').classList.remove("autofilled");
            document.getElementById('fieldPanContainer').classList.remove("autofilled");
            document.getElementById('nameContainer').classList.remove("autofilled");
            example.classList.remove('submitted');

        });
    },
    // onClick
    clickPan() {
        this.panField.focus();
    },
    clickExpiration() {
        this.expirationField.focus();
    },
    clickCVV() {
        this.cvvField.focus();
    },

    // loader
    loader(show) {
        document.getElementById("loader-example-2").style.visibility = show ? "visible" : "hidden";
        document.getElementById("customContainer").style.visibility = show ? "hidden" : "visible";
        document.getElementById("customContainer").style.opacity = show ? 0 : 1;
    },
    checkRequired(input, container) {
        if (input.value) {
            container.classList.remove("hasError");
            return true;
        } else {
            container.classList.add("hasError");
            return false;
        }
    },
    // on submit
    async submit() {
        const isNameComplete = this.checkRequired(this.name, this.nameContainer);
        const isCountryComplete = this.checkRequired(this.country, this.countryContainer);
        const isCPF_CNPJComplete = this.checkRequired(this.cpf_cnpj, this.cpf_cnpjContainer);
        const isPanOk = !this.isPanEmpty && !this.panHasError;
        const isExpirationOk = !this.isExpirationEmpty && !this.expirationHasError;
        const isCvvOk = !this.isCvvEmpty && !this.cvvHasError;

        if (!isPanOk) {
            document.getElementById('fieldPanContainer').classList.add("Field--required");
            document.getElementById('fieldPanContainer').classList.add("hasError");
        }
        if (!isExpirationOk) {
            document.getElementById('fieldExpirationContainer').classList.add("Field--required");
            document.getElementById('fieldExpirationContainer').classList.add("hasError");
        }
        if (!isCvvOk) {
            document.getElementById('fieldCVVContainer').classList.add("Field--required");
            document.getElementById('fieldCVVContainer').classList.add("hasError");
        }

        if (!isPanOk || !isExpirationOk || !isCvvOk || !isNameComplete || !isCountryComplete || !
            isCPF_CNPJComplete) {
            return;
        }
        await this.loader(true)
        this.dlocalInstance.createToken(this.panField, {
                name: this.name.value
            }).then((result) => {
                this.loader(false)
                const example = document.querySelector(".example-2");
                example.querySelector(".token").innerText = result.token;
                example.classList.add("submitted");
            })
            .catch((response) => {
                this.loader(false)
            })
        }
    }
}
</script>

<style lang="less" scoped>
.dlocal-wrap {
  overflow-y: auto;
  height: 100%;

}
</style>

<style lang="less" scoped>
    .mainContent {
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .container,
    .container-fluid,
    .container-lg,
    .container-wide,
    .container-xl {
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
    }

    .container,
    .container-lg {
        max-width: 1040px;
    }

    .container-wide,
    .container-xl {
        max-width: 1160px;
    }

    .title {
        font-weight: 300;
        font-size: 45px;
        line-height: 60px;
        color: #32325d;
        letter-spacing: -.01em;
    }

    .subtitle {
        font-weight: 400;
        font-size: 21px;
        line-height: 31px;
        color: #525f7f;
    }

    .common-BodyText {
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        color: #6b7c93;
    }

    .common-Link {
        color: #7F17AF;
        font-weight: 500;
        transition: color 0.1s ease;
        cursor: pointer;
    }

    .common-Link:hover {
        color: #32325d;
    }

    .common-Link:active {
        color: #000;
    }

    .common-Button {
        white-space: nowrap;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        background: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        color: #7F17AF;
        text-decoration: none;
        transition: all 0.15s ease;
    }

    .common-Button:hover {
        color: #260744;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }

    .common-Button:active {
        color: #260744;
        background-color: #f6f9fc;
        transform: translateY(1px);
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    .common-Button--default {
        color: #fff;
        background: #7F17AF;
    }

    .common-Button--default:hover {
        color: #fff;
        background-color: #260744;
    }

    .common-Button--default:active {
        color: #e6ebf1;
        background-color: #260744;
    }

    .common-Button--dark {
        color: #fff;
        background: #32325d;
    }

    .common-Button--dark:hover {
        color: #fff;
        background-color: #7F17AF;
    }

    .common-Button--dark:active {
        color: #e6ebf1;
        background-color: #32325d;
    }

    .common-Button--disabled {
        color: #fff;
        background: #aab7c4;
        pointer-events: none;
    }

    .common-ButtonIcon {
        display: inline;
        margin: 0 5px 0 0;
        position: relative;
    }

    .common-ButtonGroup {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: -10px;
    }

    .common-ButtonGroup .common-Button {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin: 10px;
    }

    /** Page-specific styles */

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(1turn);
        }
    }

    @keyframes void-animation-out {
        0%,
        to {
            opacity: 1;
        }
    }

    body {
        overflow-x: hidden;
        background-color: #f6f9fc;
    }

    main {
        position: relative;
        display: block;
        z-index: 1;
    }

    main>.container-lg {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        position: relative;
        max-width: 750px;
        padding: 30px 20px 110px;
    }

    main>.container-lg .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        -ms-flex: auto;
        flex: auto;
        min-width: 100%;
        min-height: 350px;
        padding: 0 40px;
    }

    main>.container-lg .row+.row {
        margin-top: 80px;
    }

    main>.container-lg .row.intro {
        padding: 0;
    }

    main>.container-lg .row.intro>* {
        width: 100%;
        max-width: 700px;
    }

    main>.container-lg .row.intro .subtitle {
        margin-top: 10px;
    }

    main>.container-lg .row.intro .common-BodyText {
        margin-top: 15px;
    }

    main>.container-lg .row.intro .common-ButtonGroup {
        width: auto;
        margin-top: 20px;
    }

    main>.container-lg .example {
        -ms-flex-align: center;
        align-items: center;
        border-radius: 4px;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        padding: 80px 0px;
        margin-left: -20px;
        margin-right: -20px;
    }

    main>.container-lg .example.submitted form,
    main>.container-lg .example.submitting form {
        opacity: 0;
        transform: scale(0.9);
        pointer-events: none;
    }

    main>.container-lg .example.submitted .success,
    main>.container-lg .example.submitting .success {
        pointer-events: all;
    }

    main>.container-lg .example.submitting .success .icon {
        opacity: 1;
    }

    main>.container-lg .example.submitted .success>* {
        opacity: 1;
        transform: none !important;
    }

    main>.container-lg .example.submitted .success> :nth-child(2) {
        transition-delay: 0.1s;
    }

    main>.container-lg .example.submitted .success> :nth-child(3) {
        transition-delay: 0.2s;
    }

    main>.container-lg .example.submitted .success> :nth-child(4) {
        transition-delay: 0.3s;
    }

    main>.container-lg .example.submitted .success .icon .border,
    main>.container-lg .example.submitted .success .icon .checkmark {
        opacity: 1;
        stroke-dashoffset: 0 !important;
    }

    main>.container-lg .example * {
        margin: 0;
        padding: 0;
    }

    main>.container-lg .example .caption {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        padding: 15px 10px 0;
        color: #aab7c4;
        font-family: Roboto, "Open Sans", "Segoe UI", sans-serif;
        font-size: 15px;
        font-weight: 500;
    }

    main>.container-lg .example .caption-top {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        top: -38px;
        left: 0;
        padding: 15px 10px 0;
        color: #aab7c4;
        font-family: Roboto, "Open Sans", "Segoe UI", sans-serif;
        font-size: 15px;
        font-weight: 500;
    }

    main>.container-lg .example .caption * {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    main>.container-lg .example .caption-top * {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    main>.container-lg .example .caption .no-charge {
        color: #cfd7df;
        margin-right: 10px;
    }

    main>.container-lg .example .caption a.source {
        text-align: right;
        color: inherit;
        transition: color 0.1s ease-in-out;
        margin-left: 10px;
    }

    main>.container-lg .example .caption a.source:hover {
        color: #7F17AF;
    }

    main>.container-lg .example .caption a.source:active {
        color: #43458b;
    }

    main>.container-lg .example .caption a.source svg {
        margin-right: 10px;
    }

    main>.container-lg .example .caption a.source svg path {
        fill: currentColor;
    }

    main>.container-lg .example form {
        position: relative;
        width: 100%;
        max-width: 500px;
        transition-property: opacity, transform;
        transition-duration: 0.35s;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    main>.container-lg .example form input::-webkit-input-placeholder {
        opacity: 1;
    }

    main>.container-lg .example form input::-moz-placeholder {
        opacity: 1;
    }

    main>.container-lg .example form input:-ms-input-placeholder {
        opacity: 1;
    }

    main>.container-lg .example .error {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        top: 100%;
        margin-top: 20px;
        left: 0;
        padding: 0 15px;
        font-size: 13px !important;
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: 0.35s;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    main>.container-lg .example .error.visible {
        opacity: 1;
        transform: none;
    }

    main>.container-lg .example .error .message {
        font-size: inherit;
    }

    main>.container-lg .example .error svg {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-top: -1px;
        margin-right: 10px;
    }

    main>.container-lg .example .success {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 10px;
        text-align: center;
        pointer-events: none;
        overflow: hidden;
    }

    main>.container-lg .example .success>* {
        transition-property: opacity, transform;
        transition-duration: 0.35s;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
        opacity: 0;
        transform: translateY(50px);
    }

    main>.container-lg .example .success .icon {
        margin: 15px 0 30px;
        transform: translateY(70px) scale(0.75);
    }

    main>.container-lg .example .success .icon svg {
        will-change: transform;
    }

    main>.container-lg .example .success .icon .border {
        stroke-dasharray: 251;
        stroke-dashoffset: 62.75;
        transform-origin: 50% 50%;
        transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
        animation: spin 1s linear infinite;
    }

    main>.container-lg .example .success .icon .checkmark {
        stroke-dasharray: 60;
        stroke-dashoffset: 60;
        transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s;
    }

    main>.container-lg .example .success .title {
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    main>.container-lg .example .success .message {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
        line-height: 1.6em;
    }

    main>.container-lg .example .success .message span {
        font-size: inherit;
    }

    main>.container-lg .example .success .reset:active {
        transition-duration: 0.15s;
        transition-delay: 0s;
        opacity: 0.65;
    }

    main>.container-lg .example .success .reset svg {
        will-change: transform;
    }

    footer {
        position: relative;
        max-width: 750px;
        padding: 50px 20px;
        margin: 0 auto;
    }

    .optionList {
        margin: 6px 0;
    }

    .optionList li {
        display: inline-block;
        margin-right: 13px;
    }

    .optionList a {
        color: #aab7c4;
        transition: color 0.1s ease-in-out;
        cursor: pointer;
        font-size: 15px;
        line-height: 26px;
    }

    .mainContent .dlocal-logo {
        width: 220px !important;
        margin-bottom: 25px;
    }

    .optionList a.selected {
        color: #7F17AF;
        font-weight: 600;
    }

    .optionList a:hover {
        color: #32325d;
    }

    .optionList a.selected:hover {
        cursor: default;
        color: #7F17AF;
    }

    .autofilled {
        background-color: #fefde5;
    }

    @media (min-width: 670px) {
        .title {
            font-size: 50px;
            line-height: 70px;
        }
        .subtitle {
            font-size: 24px;
            line-height: 36px;
        }
        main>.container-lg .example {
            padding: 40px;
        }
        main>.container-lg .example .success {
            padding: 40px;
        }
        main>.container-lg .row.intro {
            -ms-flex-align: center;
            align-items: center;
            text-align: center;
        }
        .optionList {
            margin-left: 13px;
        }
    }

    @media screen and (max-width: 670px) {
        .title {
            font-size: 45px;
            line-height: 60px;
        }
        .subtitle {
            font-size: 21px;
            line-height: 35px;
        }
        .mainContent .dlocal-logo {
            width: 160px !important;
        }
        main>.container-lg .example {
            padding: 60px 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .title {
            font-size: 36px;
            line-height: 50px;
        }
        .subtitle {
            font-size: 17px;
            line-height: 29px;
        }
        .mainContent .dlocal-logo {
            width: 135px !important;
        }
        main>.container-lg .example .success .icon {
            margin: unset;
        }
        main>.container-lg .example .success .icon svg {
            height: 45px;
        }
        main>.container-lg .example .success .title {
            font-size: 15px;
            margin-bottom: unset;
        }
        main>.container-lg .example .success .message {
            font-size: 12px;
            margin-bottom: 15px;
        }
    }
</style>

<style lang="less" scoped>
.radiocontainer {
    display: block;
    position: relative;
    padding-left: 35px !important;
    margin-bottom: 12px !important;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */

.radiocontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */

.radiocontainer:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.radiocontainer input:checked~.checkmark {
    background-color: #7F17AF;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.radiocontainer input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.radiocontainer .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Create a custom radio button */

.radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.radiocontainer:hover input~.radiomark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.radiocontainer input:checked~.radiomark {
    background-color: #7F17AF;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.radiomark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.radiocontainer input:checked~.radiomark:after {
    display: block;
}

/* Style the indicator (dot/circle) */

.radiocontainer .radiomark:after {
    top: 5px;
    left: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
}

.radiocontainer input {
    width: 0% !important;
    height: 0% !important;
}
</style>

<style lang="less" scoped>
.example.example-2 {
    background-color: #fff;
}

.example.example-2 * {
    font-family: Inter UI, Open Sans, Segoe UI, sans-serif;
}

.example.example-2 form {
    max-width: 707px !important;
    padding: 0 15px;
}

.example.example-2 form>*+* {
    margin-top: 20px;
}

.example.example-2 .container {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 3px;
}

main>.container-lg .example.submitted #fields-form-example-2 {
    opacity: 0;
    transform: scale(0.9);
    pointer-events: none;
}

.example.example-2 fieldset {
    border-style: none;
    padding: 5px;
    margin-left: -5px;
    margin-right: -5px;
    background: rgba(18, 91, 152, 0.05);
    border-radius: 8px;
}

.example.example-2 fieldset legend {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #8898aa;
    padding: 3px 10px 7px;
}

.example.example-2 .card-only {
    display: block;
}

.example.example-2 .payment-request-available {
    display: none;
}

.example.example-2 fieldset legend+* {
    clear: both;
}

.example.example-2 input,
.example.example-2 button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}

.example.example-2 button {
    color: #fff;
}

.example.example-2 input:-webkit-autofill {
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
}

.example.example-2 #example-2-card {
    padding: 10px;
    margin-bottom: 2px;
}

.example.example-2 input {
    -webkit-animation: 1ms void-animation-out;
}

.example.example-2 input::-webkit-input-placeholder {
    color: #B8BBC0;
}

.example.example-2 input::-moz-placeholder {
    color: #B8BBC0;
}

.example.example-2 input:-ms-input-placeholder {
    color: #B8BBC0;
}

.example.example-2 button {
    display: block;
    width: 100%;
    height: 37px;
    background-color: #7F17AF;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.example.example-2 button:active {
    background-color: #260744;
}

.example.example-2 .error svg .base {
    fill: #e25950;
}

.example.example-2 .error svg .glyph {
    fill: #f6f9fc;
}

.example.example-2 .error .message {
    color: #e25950;
}

.example.example-2 .success .icon .border {
    stroke: #21a35b;
}

.example.example-2 .success .icon .checkmark {
    stroke: #266b45;
}

.example.example-2 .success .title {
    color: #32325d;
}

.example.example-2 .success .message {
    color: #8898aa;
}

.example.example-2 .success .reset path {
    fill: #7F17AF;
}

.example.example-2 .field {
    height: 50px;
    width: 516px;
    margin-bottom: 20px;
    color: #333333;
}

.example.example-2 .field.half {
    display: inline-block;
    width: 253px;
    vertical-align: top;
    margin-right: 3.5px;
}

.example.example-2 .field.half+.half {
    margin-left: 3.5px;
    margin-right: 0px;
}

.example.example-2 .field.cuarter {
    display: inline-block;
    width: 122px;
    vertical-align: top;
    margin-right: 3.5px;
}

.example.example-2 .field.cuarter+.cuarter {
    margin-left: 3.5px;
    margin-right: 0px;
}

.example.example-2 .field label {
    display: block;
    color: #29303B;
    font-size: 15px;
}

.example.example-2 .field.newPayment {
    padding-top: 21px;
    margin-bottom: 0px;
}

.example.example-2 .newPayment label {
    width: fit-content;
    float: left;
}

.example.example-2 .containerX {
    margin-left: 26px;
}

.example.example-2 .exampleX {
    max-width: 707px;
    padding-bottom: 20px;
}

.example.example-2 .exampleX::after {
    content: "";
    clear: both;
    display: table;
}

.example.example-2 .custom input {
    -webkit-appearance: none;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: block;
    font-size: 15px;
    width: 100%;
    border: 1px solid #CACBCC;
    height: 50px;
    padding: 0px 12px;
    background: white;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 2px;
}

.example.example-2 .custom select {
    -webkit-appearance: none;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: block;
    font-size: 15px;
    width: 100%;
    border: 1px solid #CACBCC;
    height: 50px;
    margin-left: 3px;
    width: calc(100% - 3px);
    padding: 0px 7px;
    background: white;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 2px;
    -webkit-border-radius: 2px;
}

.example.example-2 ::placeholder {
    color: #B8BBC0;
}

.example.example-2 .custom input::-moz-placeholder {
    color: #aab7c4;
}

.example.example-2 .custom input::-webkit-input-placeholder {
    color: #aab7c4;
}

.example.example-2 .custom .fitInput {
    width: fit-content;
    float: left;
}

.example.example-2 .brand-img {
    margin-right: 13px;
}

.example.example-2 .brand-img.visa {
    height: 13.42px;
    float: left;
    margin-top: 1px;
}

.example.example-2 .brand-img.first {
    margin-left: 26px;
}

.example.example-2 .brand-img.mastercard {
    height: 20px;
    float: left;
    margin-top: -4px;
}

.example.example-2 .brand-img.amex {
    height: 20px;
    float: left;
    margin-top: -4px;
}

.example.example-2 .brand-img.discover {
    height: 8px;
    float: left;
    margin-top: 2px;
}

.example.example-2 .custom button {
    border-color: transparent;
    border-radius: 2px;
    outline: none;
    text-decoration: none;
    color: #fff;
    background: #7F17AF;
    white-space: nowrap;
    height: 50px;
    width: 269px;
    line-height: 50px;
    padding: 0 14px;
    font-size: 15px;
    letter-spacing: 0.025em;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    float: left;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.example.example-2 .custom hr {
    background-color: #DEDFE0;
    height: 1px;
    border: 0;
    margin-left: -27px;
    margin-bottom: 30px;
}

.example.example-2 .terms {
    font-size: 11px !important;
    width: 217px;
    float: left;
    margin-left: 28px;
    margin-top: 14px;
}

.example.example-2 #container {
    height: 50px;
}

.example.example-2 .field.fieldCheck {
    margin-bottom: 11px;
    margin-top: 14px;
    height: fit-content;
}

.example.example-2 .secureConnection {
    font-size: 11px;
    width: 91px;
    float: left;
    margin-left: 28px;
    margin-top: 14px;
    color: #CACBCC;
}

.example.example-2 .secureConnection img {
    float: left;
    height: 30px;
    margin-top: -5px;
}

.example.example-2 .secureConnection label {
    margin-left: 8px;
    float: left;
    width: 61px;
    margin-top: 3px;
}

.example.example-2 .errorMsgContainer {
    margin-top: 5px;
}

.example.example-2 .helpError {
    font-size: 11px;
    max-height: 30px;
    color: #686f7a;
    margin-bottom: 0;
    visibility: hidden;
    transition: visibility 0.2s ease-out 0.25s;
}

.example.example-2 .hasError .helpError {
    visibility: visible;
}

.example.example-2 .field.hasError {
    transition: margin-bottom 0.2s ease-out 0.25s;
    margin-bottom: 36px;
}

.example.example-2 .Field--required .DlocalField {
    border: 1px solid #7F17AF;
}

.example.example-2 .Field--required .helpError {
    visibility: visible;
}

.example.example-2 .DlocalField {
    background-color: white;
    height: 50px;
    padding: 10px 12px;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    border: 1px solid #CACBCC;
}

.example.example-2 .Field--required .DlocalField--empty {
    border: 1px solid #7F17AF;
}

.example.example-2 .Field--required .DlocalField--complete {
    border: 1px solid #CACBCC;
}

.example.example-2 .DlocalField--invalid {
    border: 1px solid #7F17AF;
}

.example.example-2 .DlocalField--invalid .helpError {
    visibility: visible;
}

.example.example-2 .custom .hasError select:required,
.example.example-2 .custom .hasError input:required {
    border-color: #7F17AF;
}

.example.example-2 .custom select:focus,
.example.example-2 .custom input:focus {
    border: 1px solid #CACBCC;
}

.example.example-2 .tooltipContainer {
    position: absolute;
    height: 30px;
    top: 19px;
    right: 15px;
}

.example.example-2 .tooltipImage {
    height: 16px;
}

.example.example-2 .tooltipInner {
    visibility: hidden;
    position: absolute;
    top: -95px;
    left: -94px;
    height: 20px;
    z-index: 5;
}

.example.example-2 .tooltipImage:hover+.tooltipInner {
    visibility: visible;
    transition: visibility 0.2s linear 0.25s;
}

.example.example-2 .tooltipInnerImage {
    height: 70px;
}

/*
LOADER
*/

/* Center the loader */

.example.example-2 .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 100;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #7F17AF;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.example.example-2 #panOverlay {
    visibility: hidden;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Add animation to "page content" */

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }

    to {
        bottom: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

/* autocomplete*/

.example.example-2 input:-webkit-autofill,
.example.example-2 input:-webkit-autofill:hover,
.example.example-2 input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fefde5 inset;
    box-shadow: 0 0 0px 1000px #fefde5 inset;
}

.example.example-2 #customContainer {
    visibility: hidden;
    opacity: 0;
    background: #F8F8F9;
}

@media screen and (max-width: 720px) {
    .example.example-2 .secureConnection {
        float: none;
        width: fit-content;
        margin-left: 298px;
    }

    .example.example-2 .exampleX {
        height: fit-content;
    }

    .example.example-2 .custom button {
        margin-bottom: 20px;
    }

    .example.example-2 .terms {
        margin-bottom: 20px;
    }

    .example.example-2 .field {
        width: calc(100% - 13px);
    }

    .example.example-2 .field.field.half {
        width: calc(100% - 13px);
    }

    .example.example-2 .field.half+.half {
        margin-left: 0px;
        margin-right: 0px;
    }

    .example.example-2 .field.cuarter {
        width: calc(50% - 13px);
    }

    .example.example-2 .terms {
        margin-top: 0px;
        margin-left: 0px;
        float: left;
        width: 50%;
    }

    .example.example-2 .secureConnection {
        float: left;
        margin-left: 25px;
        margin-top: 0px;
    }
}

@media screen and (max-width: 480px) {
    .example.example-2 .newPayment label {
        width: 100%;
    }

    .example.example-2 .brand-img.visa {
        margin-left: 33px;
    }

    .example.example-2 .field.newPayment {
        margin-bottom: 30px;
    }

    .example.example-2 .secureConnection {
        float: none;
        width: fit-content;
        margin-left: 0px;
    }

    .example.example-2 .terms {
        margin-left: 0px;
        width: 100%;
    }

    .example.example-2 #customContainer {
        border-radius: unset;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    .example.example-2 .exampleX {
        width: 100%;
        border: none;
        margin-top: unset;
        margin-bottom: unset;
    }

    .example.example-2 .containerX {
        margin-left: 0px;
    }

    .example.example-2 .field.field.half {
        width: 100%;
        margin-right: 0px;
    }

    .example.example-2 .field.cuarter {
        width: calc(50% - 6px);
    }

    .example.example-2 .field {
        width: 100%;
    }

    .example.example-2 .tooltipInner {
        left: -102px;
    }

    .example.example-2 .custom button {
        width: 100%;
    }

    .example.example-2 .custom select {
        margin-left: 0px;
        width: 100%;
    }

    .example.example-2 .custom hr {
        width: calc(100% + 30px);
        margin-left: -13px;
    }
}

@media screen and (max-width: 310px) {
    .example.example-2 .brand-img.mastercard {
        margin-right: 70px;
    }

    .example.example-2 .brand-img.amex {
        margin-left: 33px;
        margin-top: 4px;
    }

    .example.example-2 .brand-img.discover {
        margin-top: 9px;
    }

    .example.example-2 .field.newPayment {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 270px) {
    .example.example-2 .brand-img.mastercard {
        margin-right: 40px;
    }

    .example.example-2 .field.newPayment {
        margin-bottom: 70px;
    }
}
</style>

<style lang="less" scoped>
/**
  * Make the field a flex-container, reverse the order so label is on top.
  */

.fieldFloating {
    position: relative;
    background-color: white;
    border: 1px solid #CACBCC;
}

/**
  * Add a transition to the label and input.
  * I'm not even sure that touch-action: manipulation works on
  * inputs, but hey, it's new and cool and could remove the
  * pesky delay.
  */

.custom .hasError input:required {
    border-color: transparent !important;
}

.custom .hasError select:required {
    border-color: transparent !important;
}

.custom .hasError {
    border: 1px solid #7F17AF;
}

.custom input,
.custom select {
    border-color: transparent !important;
}

.custom select:focus,
.custom input:focus {
    border-color: transparent !important;
}

.fieldFloating label {
    cursor: text;
    z-index: 3;
    white-space: nowrap;
    transform-origin: left bottom;
    transform: translate(11px, 24px) scale(1.0);
    position: absolute;
    top: -5px;
    color: #aab7c4 !important;
}

.fieldFloating input,
.fieldFloating select {
    background-color: transparent !important;
    border: none;
}

.fieldFloating label,
.fieldFloating input,
.fieldFloating select {
    transition: all 0.2s;
    touch-action: manipulation;
}

.fieldFloating input:focus,
.fieldFloating select:focus {
    outline: 0;
}

.overlay {
    visibility: visible;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: white;
}

/**
  * Translate down and scale the label up to cover the placeholder,
  * when following an input (with placeholder-shown support).
  * Also make sure the label is only on one row, at max 2/3rds of the
  * field—to make sure it scales properly and doesn't wrap.
  */

.fieldFloating input:placeholder-shown+label,
.fieldFloating select:placeholder-shown+label {
    cursor: text;
    white-space: nowrap;
    transform-origin: left bottom;
    transform: translate(11px, 22px) scale(1.0);
    position: absolute;
    top: -5px;
}

/**
  * By default, the placeholder should be transparent. Also, it should
  * inherit the transition.
  */

.fieldFloating ::-webkit-input-placeholder,
.fieldFloating ::-webkit-select-placeholder {
    opacity: 0 !important;
    transition: inherit;
}

/**
  * Show the placeholder when the input is focused.
  */

.fieldFloating input:focus::-webkit-input-placeholder,
.fieldFloating select:focus::-webkit-select-placeholder {
    opacity: 1 !important;
}

/**
  * When the element is focused, remove the label transform.
  * Also, do this when the placeholder is _not_ shown, i.e. when
  * there's something in the input at all.
  */

.fieldFloating input:not(:placeholder-shown)+label,
.fieldFloating input:focus+label,
.fieldFloating select:not(:placeholder-shown)+label,
.fieldFloating select:focus+label {
    transform: translate(11px, 8px) scale(0.8);
    cursor: pointer;
}

.fieldFloating input:not(:placeholder-shown),
.fieldFloating input:focus,
.fieldFloating select:not(:placeholder-shown),
.fieldFloating select:focus {
    transform: translate(0px, 10px);
    cursor: pointer;
}

.DlocalField {
    background-color: transparent !important;
    border: none !important;
}

.Field--required .DlocalField--focus.DlocalField--empty {
    border: none !important;
}

.Field--required .DlocalField--empty {
    border: none !important;
}

.Field--required .DlocalField--complete {
    border: none !important;
}

.DlocalField--invalid {
    border: none !important;
}

.DlocalField--invalid .helpError {
    visibility: visible;
}

.DlocalField--autofilled {
    background-color: transparent !important;
}

.custom .hasError select:required,
.custom .hasError input:required {
    border: none !important;
}

.custom select:focus,
.custom input:focus {
    border: none !important;
}

.Field--required .DlocalField {
    border: none !important;
}

.fieldFloating .input.focus+label,
.fieldFloating .input:not(.empty)+label {
    transform: translate(11px, 8px) scale(0.8);
    cursor: pointer;
}

.fieldFloating .input.focus,
.fieldFloating .input:not(.empty) {
    transform: translate(0px, 10px);
    cursor: pointer;
}

@keyframes onAutoFillStart {
    from {
        width: 10%;
    }
    to {
        width: 100%;
    }
}

@keyframes onAutoFillCancel {
    from {
        width: 98%;
    }
    to {
        width: 100%;
    }
}

.custom input:-webkit-autofill,
.custom input:-webkit-autofill:hover,
.custom input:-webkit-autofill:focus {
    animation: onAutoFillStart 500ms;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

.custom input {
    animation: onAutoFillCancel 10ms;
}

.custom .field.autofilled input {
    background-color: transparent !important;
}

.custom .field.autofilled {
    background-color: #fefde5
}
</style>
