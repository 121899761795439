<!-- 累充活动 -->
<template>
  <div class="relative z-10 flex justify-center w-full">
    <div
      v-imloading="loading"
      :class="isMobile ? 'gears_wrap_mobile w-full' : 'gears_wrap_desk w-full'"
    >
      <!-- 我的 活动时间 -->
      <div
        :class="['activity_info flex items-center justify-between', {'px-[32px]': !isMobile && isHaveGiftRowBg}, {'px-[8px]': isMobile && isHaveGiftRowBg}]"
      >
        <span>{{ $t('siteBuild.activity.my') }}：{{(activityData.virtual_currency_num || 0)}}</span>
        <span>{{ $t('siteBuild.activity.activityTime') }}：{{sTime}}</span>
      </div>
      <!-- 档位列表 -->
      <div
        v-for="(item, index) in gearsData"
        :key="`gears_${index}`"
        :class="['gears-wrap-list w-full ', {'px-[32px]': !isMobile && isHaveGiftRowBg}, {'px-[8px]': isMobile && isHaveGiftRowBg}]"
        :style="giftBgImageStyle"
      >
        <div class="curency_logo_num flex items-center">
          <img :src="item.level_logo" class="w-[44px] h-[44px] rounded-full mr-3" alt />
          <div
            class="text-14 leading-[22px]"
            :style="numberColorStyle"
          >
            <span>{{item.virtual_currency_val}}</span>
<!--            <span class="ml-5">{{ item.level_status }}</span>-->
          </div>
        </div>
        <div class="gift_info">
          <div class="gift_list flex flex-col">
            <div
              v-for="(gift, idx) in chunkGiftData(item.gift_list || [])"
              :key="`gift_${index}_${idx}`"
              class="gift_list_row flex items-center"
            >
              <div
                v-for="(i, d) in gift"
                :key="`gift_${index}_${idx}_${d}`"
                class="gift_list_row_item flex flex-col relative"
              >
                <img :src="i.gift_goods_logo" class="w-full h-[86px]" alt />
                <div class="item-num" :style="numberColorStyle">{{i.num}}</div>
                <div
                  v-if="item.level_status === 3 && model.receivedMaskShow"
                  class="absolute top-0 left-0 bottom-0 right-0 z-30"
                  :style="receivedMaskStyle"
                ></div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-center item-gift">
            <!-- 未达标 -->
            <ElButton
              v-if="item.level_status === 1"
              type="info"
              disabled
              :style="{...nqBtnImgStyle, ...nqBtnColorStyle}"
              class="sum_btn"
              @click="handlePick(item)"
            >{{$t('siteBuild.activity.notQualified')}}</ElButton>
            <!-- 领取 -->
            <ElButton
              v-if="item.level_status === 2"
              type="primary"
              class="sum_btn"
              :disabled="editing"
              :style="{...getBtnImgStyle, ...getBtnColorStyle}"
              @click="handlePick(item)"
            >{{$t('siteBuild.activity.get')}}</ElButton>
            <!-- 已领取 -->
            <ElButton
              v-if="item.level_status === 3"
              type="info"
              class="sum_btn"
              disabled
              :style="{...receivedBtnImgStyle, ...receivedBtnColorStyle}"
              @click="handlePick(item)"
            >{{$t('siteBuild.activity.haveGet')}}</ElButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chunk from 'lodash.chunk'
import isNil from 'lodash.isnil'
import { mapState } from 'vuex'
import moment from 'moment'
import { Button } from 'element-ui'
import tools from '~/utils'
import Bus from '~/site/model/bus'
import { isBuildWebSite, isPre } from '~/config'
import { GET_SITE_ID } from '~/config/index'

let isPreviewMode = !isBuildWebSite
const SiteID = GET_SITE_ID()
// eslint-disable-next-line no-console,eqeqeq
if (isBuildWebSite && isPre && SiteID.startsWith('56') && SiteID.length === 2) {
  isPreviewMode = true
}

export default {
  name: 'SumComponents',
  components: {
    ElButton: Button
  },
  props: {
    model: {
      type: Object,
      default: () => { }
    },
    isMobile: Boolean,
    isHaveGiftRowBg: Boolean,
    editing: Boolean
  },
  data() {
    return {
      loading: false,
      activityData: {},
      gearsData: [],
      sTime: '00:00:00'
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    }),
    // 未达标按钮
    nqBtnImgStyle() {
      const { nqBtnImageShow, nqBtnImage, nqBtnImageOpacity } = this.model
      if (nqBtnImageShow && nqBtnImage) {
        return {
          backgroundImage: `url(${nqBtnImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          opacity: nqBtnImageOpacity
        }
      }
      return { background: '#b3b3b3 !important' }
    },
    nqBtnColorStyle() {
      const { nqBtnColorShow, nqBtnColor } = this.model
      return nqBtnColorShow ? { color: nqBtnColor } : { color: '#fff' }
    },
    // 领取按钮
    getBtnImgStyle() {
      const { getBtnImageShow, getBtnImage, getBtnImageOpacity } = this.model
      if (getBtnImageShow && getBtnImage) {
        return {
          backgroundImage: `url(${getBtnImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          opacity: getBtnImageOpacity
        }
      }
      return {}
    },
    getBtnColorStyle() {
      const { getBtnColorShow, getBtnColor } = this.model
      return getBtnColorShow ? { color: getBtnColor } : { color: 'unset' }
    },
    // 已领取按钮
    receivedBtnImgStyle() {
      const { receivedBtnImageShow, receivedBtnImage, receivedBtnImageOpacity } = this.model
      if (receivedBtnImageShow && receivedBtnImage) {
        return {
          backgroundImage: `url(${receivedBtnImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          opacity: receivedBtnImageOpacity
        }
      }
      return { background: '#b3b3b3 !important' }
    },
    receivedBtnColorStyle() {
      const { receivedBtnColorShow, receivedBtnColor } = this.model
      return receivedBtnColorShow ? { color: receivedBtnColor } : { color: '#fff' }
    },
    // 领取遮罩层
    receivedMaskStyle() {
      const { receivedMaskShow, receivedMask, receivedMaskOpacity } = this.model
      if (receivedMaskShow && receivedMask) {
        return {
          backgroundImage: `url(${receivedMask})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          opacity: receivedMaskOpacity
        }
      }
      return { background: 'rgba(0, 0, 0, 0.2)' }
    },
    // 数字颜色
    numberColorStyle() {
      const { numberColorShow, numberColor } = this.model
      return numberColorShow ? { color: numberColor } : { color: 'unset' }
    },
    // 奖励单行背景
    giftBgImageStyle() {
      const { giftBgImageShow, giftBgImage, giftBgImageOpacity } = this.model
      if (giftBgImageShow && giftBgImage) {
        return {
          backgroundImage: `url(${giftBgImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          opacity: giftBgImageOpacity
        }
      }
      return {}
    }
  },
  created() {
    this.getActivityData()
  },
  mounted() {
    Bus.$on('reloadActivity', () => {
      this.getActivityData()
    })
  },
  destroyed() {
    Bus.$off('reloadActivity')
  },
  methods: {
    // 领取
    handlePick(item) {
      console.log('--handlePick--', item);
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        // 只通过 player_id登录的系统（游客模式）
        this.$store.commit('user/SET_SITE_BIND_USER', true)
      } else {
        this.loading = true
        const device = this.isMobile ? 'mobile' : 'desktop'
        const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
        const return_url = `${window.location.protocol}//${window.location.hostname === 'localhost' ? 'sitebuilder.im30.net' : window.location.host}${window.location.pathname}`
        const gift_items = item.gift_list.map(i => {
          i.logo = i.gift_goods_logo
          return i
        })

        this.$api.activity.pickActivityItems({
          project_id: this.projectId || this.userInfo.project_id || '',
          merchant_id: this.userInfo.merchant_id || '',
          gift_items,
          activity_id: this.activityData.biz_id,
          player_id: (this.userInfo && this.userInfo.uid || '').trim(),
          level_virtual_currency_val: item.virtual_currency_val,
          from_preview_mode: isPreviewMode,
          settings: {
            device,
            language: lang,
            show_order: false,
            return_url
          }
        })
          .then(() => {
            this.getActivityData()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    getActivityData() {
      this.loading = true
      // const now_stem = tools.getNowUtcStamp()
      const now_stem = Math.floor(moment.utc().valueOf() / 1000)
      this.$api.activity.getActivityItems({
        now_time: now_stem,
        player_id: (this.userInfo && this.userInfo.uid || '').trim(),
        project_id: this.projectId || this.userInfo.project_id || ''
      }, { editing: this.editing })
        .then(res => {
          this.activityData = res
          this.gearsData = res.activity_level_hit_list || []
          this.countTime()
        })
        .catch(() => {
          this.activityData = {}
          this.gearsData = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    countTime() {
      if (!this.activityData.activity_stop_time || this.activityData.activity_stop_time === 4822948800) return
      // 获取当前时间
      // const date = new Date();
      // const now = tools.getNowUtcStamp() * 1000;
      const now = moment.utc().valueOf();
      // 设置截止时间
      const str = moment(this.activityData.activity_stop_time * 1000).utc().format('YYYY-MM-DD HH:mm:ss');
      const end = new Date(`${str} GMT+0000`).valueOf();
      // 时间差
      const leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      let d = 0; let h = 0; let m = 0; let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.sTime = `${d > 0 ? `${d}:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`

      // 递归每秒调用countTime方法，显示动态时间效果
      if (leftTime > 0) setTimeout(this.countTime, 1000);
    },
    // 分组
    chunkGiftData(arr) {
      return chunk(arr, this.isMobile ? 4 : 9)
    }
  },
}
</script>
<style lang="less" scoped>
.gears_wrap_desk {
  .activity_info {
    font-family: PingFang SC;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .gears-wrap-list {
    padding-top: 24px;
    padding-bottom: 24px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .curency_logo_num {
      margin-bottom: 24px;
    }

    .gift_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .gift_list {
        row-gap: 24px;
        .gift_list_row {
          column-gap: 32px;

          .gift_list_row_item {
            width: 86px;

            .item-num {
              min-height: 24px;
              font-size: 14px;
              line-height: 22px;
              text-align: center;
            }
          }
        }
      }

      .sum_btn {
        min-width: 80px;
        height: 36px;
        border-radius: 2px;
        padding: 0 12px;
        border: none;
      }
    }
  }
}

.gears_wrap_mobile {
  .activity_info {
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .gears-wrap-list {
    padding-top: 12px;
    padding-bottom: 12px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .curency_logo_num {
      margin-bottom: 16px;
    }

    .gift_info {
      display: flex;
      flex-direction: column;

      .gift_list {
        row-gap: 8px;

        .gift_list_row {
          column-gap: 8px;
          justify-content: center;

          .gift_list_row_item {
            width: calc((100% - 24px) / 4);

            .item-num {
              min-height: 24px;
              font-size: 14px;
              line-height: 22px;
              text-align: center;
            }
          }
        }
      }

      .item-gift {
        margin-top: 16px;
      }

      .sum_btn {
        min-width: 72px;
        height: 32px;
        border-radius: 2px;
        padding: 0 8px;
        border: none;
      }
    }
  }
}
</style>
