var render = function render(){var _vm=this,_c=_vm._self._c;return _c('bg-style',{class:['good', 'relative', 'p-0', 'pt-4', 'flex-col', 'layout-col', {
      skeleton: _vm.isTmp
    }],attrs:{"id":`store-good-id-${_vm.good.id}`,"bg":_vm.model.goodsBackground}},[(_vm.isHasCorner)?_c('im-image',{staticClass:"absolute top-0 right-0 good-logo corner-logo",attrs:{"src":_vm.good.corner,"is-lazy":"","scroll-container":_vm.lazyScrollContainer}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col info-wrapper"},[_c('div',{staticClass:"popover-wrapper"},[(_vm.goodName)?_c('el-popover',{attrs:{"append-to-body":!_vm.editing,"placement":"bottom","width":"200","trigger":"click"}},[_c('div',[_vm._v(_vm._s(_vm.goodName))]),_vm._v(" "),_c('div',{class:['name', {
              'has-corner': _vm.isHasCorner
            }, 'relative'],style:({
              'color': _vm.model.goodsTitleColorShow
                ? _vm.model.goodsTitleColor
                : 'unset',
            }),attrs:{"slot":"reference"},slot:"reference"},[_vm._v("\n            "+_vm._s(_vm.goodName)+"\n          ")])]):_c('div',{staticClass:"name",style:({
            'color': _vm.model.goodsTitleColorShow
              ? _vm.model.goodsTitleColor
              : 'unset',
          })},[_vm._v("\n          "+_vm._s(_vm.goodName)+"\n        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"flex justify-center icon",on:{"click":_vm.openDescriptionPopover,"mouseleave":_vm.cardMouseLeave}},[(_vm.isTmp)?_c('im-icon',{staticClass:"text-[36px] tmp-image",attrs:{"icon":"icon-a-emptyproduct"}}):_c('im-image',{staticClass:"relative good-logo",attrs:{"src":_vm.good.icon,"is-lazy":"","scroll-container":_vm.lazyScrollContainer}})],1),_vm._v(" "),(_vm.goodDescription)?_c('el-popover',{attrs:{"append-to-body":!_vm.editing,"placement":"top","width":"200","trigger":"focus"},model:{value:(_vm.descPopoverVisible),callback:function ($$v) {_vm.descPopoverVisible=$$v},expression:"descPopoverVisible"}},[_c('div',[_vm._v(_vm._s(_vm.goodDescription))]),_vm._v(" "),_c('div',{staticClass:"descriptions relative",style:({
          'color': _vm.model.goodsDescriptionColorShow
            ? _vm.model.goodsDescriptionColor
            : 'unset',
        }),attrs:{"slot":"reference"},on:{"click":_vm.openDescriptionPopover,"mouseleave":_vm.cardMouseLeave},slot:"reference"},[_vm._v("\n        "+_vm._s(_vm.goodDescription)+"\n      ")])]):_c('div',{staticClass:"descriptions relative",style:({
        'color': _vm.model.goodsDescriptionColorShow
          ? _vm.model.goodsDescriptionColor
          : 'unset',
      })},[_vm._v("\n      "+_vm._s(_vm.goodDescription)+"\n    ")]),_vm._v(" "),_c('div',{class:['relative', 'flex', 'flex-col', 'flex-wrap', 'items-start', 'justify-between', 'cursor-pointer', 'bottom-wrapper', {
        'cursor-not-allowed': _vm.isSaleOut
      }]},[_c('div',{staticClass:"flex w-full h-full buy-box",class:{ 'is-disabled': _vm.isSaleOut }},[(_vm.model.shopCartEnabled)?_c('shop-cart',{staticClass:"w-[40%]",attrs:{"good":_vm.good,"disabled":_vm.editing,"model":_vm.model}}):_vm._e(),_vm._v(" "),_c('bg-style',{staticClass:"flex items-center justify-center flex-1 text-center price-info relative",attrs:{"bg":_vm.model.buyBackground},nativeOn:{"click":function($event){return _vm.buy(_vm.good)}}},[(_vm.isShowCountdown)?_c('div',{staticClass:"timer-style"},[_vm._v("\n            "+_vm._s(_vm.countdownDays)+":"+_vm._s(_vm.countdownHours)+":"+_vm._s(_vm.countdownMinutes)+":"+_vm._s(_vm.countdownSeconds)+"\n          ")]):_c('div',{staticClass:"relative"},[(_vm.isTmp)?_c('div',{staticClass:"price text-24"},[_vm._v("$XX.XX")]):_c('div',[(_vm.isShowOriginalPrice)?_c('div',{staticClass:"line-through price-original"},[_vm._v("\n                "+_vm._s(_vm.originalPriceText)+"\n              ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"price",style:(_vm.getColorStyle(_vm.model.goodsPriceColor, _vm.model.goodsPriceColorShow))},[_vm._v(_vm._s(_vm.realPriceText))])])])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }