<template>
  <div class="rich-wrap">
    <div contenteditable="true"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="less">

</style>
