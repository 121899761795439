<template>
<!--  <SiteIndex v-if="model" :white-list="whiteList" :model="model"></SiteIndex>-->
  <SiteIndex v-if="!native && model" :white-list="[]" :model="model" />
  <site-shop-cart v-else-if="(native === PageNameEnum.SHOP_CART) && model" :model="model" />
</template>

<script>
import SiteIndex from "~/site/components/siteIndex"
import SiteCore, { getSiteBuilderDataByService } from "~/site/core";
import { useAssets } from "~/hooks/useAssets";
import tool from '~/utils';
import langConfigMixins from "~/mixins/langConfigMixins";
import SiteShopCart from "~/site/components/shopCartPage/index.vue";
import {PageNameEnum} from "~/enums/pageEnum";

export default {
  name: "SiteIndexPage",
  components: {
    SiteShopCart,
    SiteIndex
  },
  mixins: [langConfigMixins],
  layout: 'defaultLayout',
  async asyncData(options) {
    const isProd = true
    return await getSiteBuilderDataByService({ ...options, isProd })
  },
  data() {
    return {
      model: null,
      lang: {},
      pLang: '',
      native: null,
      mid: '',
      pid: '',
      domain: '',
      siteBuildId: '',
      langList: [],
      pageList: [],
      siteInfo: {
        default_language: '',
        published_conf: {}
      },
      baseInfo: {},
      languages: []
    }
  },
  head() {
    if (!this.web.site) {
      return {}
    }
    return {
      title: this.web.site.seoSetting.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.web.site.seoSetting.desc
        },
      ],
      link: [
        {
          rel: 'icon',
          hid: 'favicon.ico',
          type: 'image/x-icon',
          href: this.web.site.seoSetting.favicon
        },
      ]
    }
  },
  computed: {
    PageNameEnum() {
      return PageNameEnum
    }
  },
  mounted() {
    // 应用支持的语言列表
    const { setSiteLangList } = useAssets(this)
    setSiteLangList(this.langList)
    this.setNewUrl()
  },
  methods: {
    // 设置语言
    async setNewUrl() {
      const params = tool.getNeedParamsFormRouteParams(this.$route.params)
      this.native = params.native
      if (!params.lang && !params.native) {
        const sysLang = tool.getSysLanguage()
        console.log('===浏览器语言===', sysLang);
        const langArr = this.langList.map(item => item.code)
        console.log('===商城语言列表===', langArr, this.langList);
        const curLang = langArr.includes(sysLang) ? sysLang : this.siteInfo.default_language
        this.pLang = curLang
        const fullPath = `/${curLang}/`
        console.log('===fullPath===', fullPath);
        this.$nextTick(async () => {
          if (window) {
            const url = `${window.location.origin}${fullPath}`
            window.location.href = url
          } else {
            await this.$router.push(fullPath)
          }
        })
      } else {
        this.pLang = params.lang
        await this.init()
      }
      this.$cookies.set('lang', this.pLang)
    },
    // 初始化页面
    async init() {
      const lang = this.pLang || this.siteInfo.default_language;
      const langIdData = await this.getLangConfig(lang, this.siteInfo.default_language, this.pid)

      // console.log('===shop siteInfo===', this.siteInfo);
      this.model = new SiteCore({
        editMode: false,
        siteInfo: this.siteInfo.published_conf,
        baseInfo: this.baseInfo,
        languages: this.languages,
        langList: this.langList,
        lang,
        langIdData,
        pageList: this.pageList,
        isProd: true
      })
      console.log('=====商城=====', this.model)
      this.$store.commit('locale/SET_LANG', this.pLang || this.siteInfo.default_language)
      this.$store.commit('locale/SET_LANG_DATA', langIdData)
    }
  }
}
</script>

<style scoped></style>
