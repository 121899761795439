<template>
  <button type="button" :disabled="disabled" :border="true" class="sit-count-down">
     <span v-show="seconds">{{seconds}}s</span>
     <span v-show="!seconds">{{$t('siteBuild.header.smsCode')}}</span>
  </button>
</template>

<script>
export default {
  name: "Countdown",
  components:{
  },
  props: {
    value: {type: String},
    size: {type: String, validator: (v) => ['default', 'large', 'small'].includes(v)},
    count: {type: Number, default: 120},
    disabled: {type: Boolean, default: false},
  },
  data(){
    return {
      seconds: 0
    }
  },
  methods:{
    sendCode(){
        this.start()
    },
    start(){
     this.seconds = this.count
     this._timerId = setInterval(()=>{
       if(this.seconds === 0){
         this.stop()
       }else{
         this.seconds --
       }
     },1000)
    },
    stop(){
      this.seconds = 0
      this._timerId && clearInterval(this._timerId)
    },
  }
}
</script>

<style lang="less" scoped>
.sit-count-down{
  border: 1px solid #E5E6EB;
  color: #4E5269;
  display: inline-block;
  padding: 0px 12px;
  line-height: 36px;
  border-radius: 2px;
  min-width: 114px;
  box-sizing: border-box;
  &:hover{
    border: 1px solid rgba(229,230,235, 0.6);
    color: rgba(78,82,105,0.6);
  }
  &:disabled{
    border: 1px solid rgba(229,230,235, 0.6);
    color: #C9C9D4;
    background: #E5E6EB;
    cursor: not-allowed;
  }
}
</style>
