<template>
  <div class="dlocal-wrap">
    <form id="payment-form" action="http://127.0.0.1:4242/createTimor" method="post">
        <div class="form-row">
            <label for="card-field">
                Credit or Debit card
            </label>
            <div id="card-field">
                <!-- A dLocal Smart Field will be inserted here. -->
            </div>

            <!-- Used to display Smart Field errors. -->
            <div id="card-errors" role="alert"></div>
        </div>
        <div class="form-rowd">
            <label>Cardholder name</label>
            <input id="card-holder" type="text" name="card-holder" placeholder="John Doe" />
        </div>
        <button>Pay</button>
    </form>
  </div>
</template>

<script>

export default {
  name: "Dlocal",
  data() {
    return {
        api_key: 'efba0f53-252d-4da2-806c-3cb694a1e2d8', // '84695ef7cdc59834d9d268aded55f9666'
    }
  },
  head: {
    script: [
      { src: 'https://js-sandbox.dlocal.com' }
    ]
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
        // eslint-disable-next-line
        const imdlocal = dlocal(this.api_key)
        const fields = imdlocal.fields({
            locale: 'en',
            country: 'BR'
        });

        const style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '16px',
                color: "#32325d",
            }
        };

        const card = fields.create('card', {style});

        card.mount(document.getElementById('card-field'));

        card.addEventListener('change', function(event) {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        const form = document.getElementById('payment-form');
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            const cardHolderName = document.getElementById('card-holder').value;
            imdlocal.createToken(card, {
                name: cardHolderName
            }).then(function(result) {
                dlocalTokenHandler(result.token);
            }).catch((result) => {
                if (result.error) {
                    // Inform the customer that there was an error.
                    const errorField = document.getElementById('card-errors');
                    errorField.textContent = result.error.message;
                }
            });
        });

        function dlocalTokenHandler(token) {
            // Insert the token ID into the form so it gets submitted to the server
            const form = document.getElementById('payment-form');
            const tokenInput = document.createElement('input');
            tokenInput.setAttribute('type', 'hidden');
            tokenInput.setAttribute('name', 'dlocalToken');
            tokenInput.setAttribute('value', token);
            form.appendChild(tokenInput);

            // Submit the form
            form.submit();
        }
    },
  }
}
</script>

<style lang="less" scoped>
.dlocal-wrap {
  overflow-y: auto;
  height: 100%;

}
</style>
