<template>
  <widget-content
    :class="isMobile ? 'activity_content_mobile relative' : 'activity_content_desk relative'"
    :bg="{...model, backgroundOpacity: model.backgroundImageOpacity }"
  >
    <div
      v-if="showTitle"
      :class="['flex items-center justify-center', !isMobile ? 'mb-[8px]' : 'mb-[4px]', {'px-[32px]': !isMobile && isHaveGiftRowBg}, {'px-[8px]': isMobile && isHaveGiftRowBg}]"
    >
      <rich-text
        v-model="model.layout[0].titleText"
        :editing="editing"
        :disabled="!editing"
        placement="top"
        theme="snow"
        class="w-full activity_title title"
      ></rich-text>
    </div>
    <div
      :class="['flex items-center justify-center', !isMobile ? 'mb-[30px]' : 'mb-[16px]',{'px-[32px]': !isMobile && isHaveGiftRowBg}, {'px-[8px]': isMobile && isHaveGiftRowBg}]"
    >
      <rich-text
        v-if="showDesc"
        v-model="model.layout[1].titleText"
        :editing="editing"
        :disabled="!editing"
        placement="top"
        theme="snow"
        class="w-full activity_desc title"
      ></rich-text>
    </div>
    <!-- 累充活动板块 -->
    <SumComponents
      v-if="model.activityType === '2'"
      :model="model"
      :is-mobile="isMobile"
      :is-have-gift-row-bg="isHaveGiftRowBg"
      :editing="editing"
      class="w-full"
    />
  </widget-content>
</template>

<script>
import { ActivityWidgetModel } from '../../model/index'
import SumComponents from './sum.vue'
import WidgetContent from '~/site/components/widgetContent'
import richText from '~/components/richText'

export default {
  name: 'ActivityWidgetEdit',
  components: {
    richText,
    WidgetContent,
    SumComponents
  },

  props: {
    model: {
      type: Object,
      default() {
        return new ActivityWidgetModel()
      },
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {}
  },
  computed: {
    showTitle() {
      const res = this.model.layout.find((m) => {
        return m.key === 'activityTitle'
      })
      return res && res.value
    },
    showDesc() {
      const res = this.model.layout.find((m) => {
        return m.key === 'activityDesc'
      })
      return res && res.value
    },
    isMobile() {
      return this.device === 'mobile'
    },
    // 是否有奖励行背景
    isHaveGiftRowBg() {
      const { giftBgImageShow, giftBgImage } = this.model
      return !!(giftBgImageShow && giftBgImage)
    }
  },
}
</script>

<style lang="less" scoped>
.activity_content_desk {
  padding: 86px 0;

  .activity_title {
    font-size: 46px;
    line-height: 70px;
    // text-transform: uppercase;
  }

  .activity_desc {
    font-size: 24px;
    line-height: 36px;
  }
}

.activity_content_mobile {
  padding: 32px 16px;

  .activity_title {
    font-size: 24px;
    line-height: 36px;
    // text-transform: uppercase;
  }

  .activity_desc {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
