<!-- 资料库-->
<template>
  <div class="upload-assets__wrapper">
    <upload
      class="assets-uploader"
      action=""
      :show-file-list="false"
      :http-request="uploadRequest"
      accept=".ico,.png,.gif,.jpg,.jpeg"
      :multiple="true"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload">
      <upload-trigger :text="uploadText || $t('upload.text')" :tip="tip || $t('upload.limit',{limit})" :suggestion="suggestion"></upload-trigger>
    </upload>
    <div :class="['upload-list__wrapper mt-8px', {
      'overflow-widget': inWidget
    }]">
      <upload-list :data="assetsList" :in-widget="inWidget" :current-url="value" @delete="deleteMethod" @change="changeCheckedMethod"></upload-list>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'
import { mapState } from 'vuex'
import { Upload, Message } from 'element-ui'
import UploadList from './upload/uploadList'
import UploadTrigger from './upload/uploadTrigger'
import { useAssets } from '~/hooks/useAssets'

export default {
  name: "AssetsMaterial",
  components: {
    Upload,
    UploadList,
    UploadTrigger,
  },
  model: {
    prop: 'value:',
    event: 'change'
  },
  props: {
    uploadText: {
      type: String,
      default: ''
    },
    inWidget: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 2 // 2Mb
    },
    suggestion: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    const { assetsList, uploadFileRequest, deleteAssetsByUrl, getAssets } = useAssets(this)
    return {
      imageUrl: '',
      assetsList,
      uploadFileRequest,
      deleteAssetsByUrl,
      getAssets
    }
  },
  computed: {
    ...mapState({
      projectId: state => state.project.projectId,
      merchantId: state => state.project.merchantId,
      domain: state => state.project.domain,
    })
  },
  created() {
    this.throttleGetAssets = throttle(this.getAssets, 1500)
    // this.getAssets(this.domain, this.merchantId, this.projectId)
  },
  methods: {
    init() {
      this.throttleGetAssets(this.domain, this.merchantId, this.projectId)
    },
    // getAssetsList() {
    //   this.$api.assets.getAssets({directory: this.$route.pid || 'test', merchant_id: 9,project_id: 12}).then(res=>{
    //     const data = res.data || []
    //     this.__assetsList = data
    //     this.assetsList = data.map((item => item.url))
    //   })
    // },
    deleteMethod(imgUrl) {
      this.deleteAssetsByUrl(imgUrl)
    },
    changeCheckedMethod(imgUrl) {
      this.$emit('change', imgUrl)
    },
    uploadRequest(options) {
      const formData = new FormData();
      this.assetsList.unshift({
        uid: options.file.uid,
        loading: true
      })
      const user = this.$cookies.get('userInfo')
      formData.set('operator', user?.id || '')
      formData.append('file', options.file)
      formData.append('merchant_id', this.merchantId)
      formData.append('project_id', this.projectId)
      formData.append('directory', this.domain)
      this.uploadFileRequest(formData).then(res => {
        const index = this.assetsList.findIndex(asset => asset?.uid === options.file.uid)
        if (index < 0) {
          options.onError();
          return
        }
        this.$set(this.assetsList, index, {
          ...this.assetsList[index],
          url: res.url,
          loading: false
        })
        console.log('getAssets', this.getAssets, this.domain, this.merchantId, this.projectId)
        this.throttleGetAssets(this.domain, this.merchantId, this.projectId)
        options.onSuccess(res);
      }).catch(() => {
        const index = this.assetsList.findIndex(asset => asset?.uid === options.file.uid)
        this.assetsList.splice(index, 1)
        options.onError();
      })
    },
    handleAvatarSuccess(res, file) {
      //
    },
    beforeAvatarUpload(file) {
      const isLtM = file.size / 1024 / 1024 < this.limit;
      // if (!isJPG) {
      //   Message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLtM) {
        Message.error(`上传头像图片大小不能超过${this.limit}MB!`);
      }
      return isLtM;
    }
  }
}
</script>

<style lang="less" scoped>
.upload-assets__wrapper {
  .assets-uploader {
    /deep/ .el-upload {
      text-align: left;
      width: 100%;
    }
  }

  .overflow-widget {
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>
