<template>
  <div id="pay-wrap" class="overflow-auto h-[100vh]">
    <el-dialog title="支付服务配置" :visible.sync="visible">
      <el-form ref="form" :model="form" :rules="rules" label-position="top" size="small">
        <el-form-item label="支付平台" prop="platform">
          <el-select v-model="form.platform" placeholder="请选择测试的支付平台" class="w-full" @change="onPlatformChange">
            <el-option v-for="item in platformOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="环境(env)" prop="env">
          <el-select v-model="form.env" class="w-full">
            <el-option v-for="item in envOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型(device)" prop="device">
          <el-select v-model="form.device" class="w-full">
            <el-option v-for="item in deviceOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="语言(language)" prop="device">
          <el-select v-model="form.language" class="w-full">
            <el-option v-for="item in languageOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="国家(country)" prop="country">
          <el-select v-model="form.country" class="w-full" @change="countryChange">
            <el-option v-for="item in countryOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="货币(currency)" prop="currency">
          <el-select v-model="form.currency" class="w-full" @change="currencyChange">
            <el-option v-for="item in currencyOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="沙盒模式(sandbox)" prop="sandbox">
          <el-switch v-model="form.sandbox"></el-switch>
        </el-form-item>
        <el-form-item label="调试模式(debug)" prop="debug">
          <el-switch v-model="form.debug"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="validForm">打开支付</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </el-dialog>
    <div class="flex flex-col w-full p-10">
      <sub-title :title="form.sandbox ? '当前模式：沙盒' : '当前模式：正式'" class="mb-5" :font-style="{ color: 'white' }" />
      <sub-title title="参数编辑器" :font-style="{ color: 'white' }" />
      <m-editor v-model="queryParams" class="mt-5" theme="" />
<!--      <div class="flex items-center mt-3">-->
<!--        <button class="p-3 mt-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="generateToken">获取支付Token</button>-->
<!--        <button class="p-3 mt-5 ml-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="handleClickCdn">打开支付</button>-->
<!--      </div>-->
<!--      <div class="mt-10">-->
<!--        <sub-title title="Mycard支付" :font-style="{ color: 'white' }" />-->
<!--        <button class="p-3 mt-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="generateToken({ country: 'HK', currency: 'TWD' })">获取Mycard支付Token</button>-->
<!--        <button class="p-3 mt-5 ml-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="generateToken({ country: 'HK', currency: 'TWD', settings: { device: 'mobile' } })">获取Mycard移动端支付Token</button>-->
<!--        <button class="p-3 mt-5 ml-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="handleClickCdn">打开支付</button>-->
<!--      </div>-->
<!--      <div class="mt-10">-->
<!--        <sub-title title="Xsolla支付" :font-style="{ color: 'white' }" />-->
<!--        <button class="p-3 mt-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="generateToken({ country: 'US', currency: 'USD' })">获取Xsolla支付Token</button>-->
<!--        <button class="p-3 mt-5 ml-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="generateToken({ country: 'US', currency: 'USD', settings: { device: 'mobile'  } })">获取Xsolla移动支付Token</button>-->
<!--        <button class="p-3 mt-5 ml-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="handleClickCdn">打开支付</button>-->
<!--      </div>-->
<!--      <div class="mt-10">-->
<!--        <sub-title title="Dlocal支付" :font-style="{ color: 'white' }" />-->
<!--        <button class="p-3 mt-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="generateToken(dlocalParams)">获取Dlocal支付Token</button>-->
<!--        <button class="p-3 mt-5 ml-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="generateToken(Object.assign({}, dlocalParams, { settings: {device: 'mobile'} }))">获取Dlocal移动支付Token</button>-->
<!--        <button class="p-3 mt-5 ml-5 border text-14 pay border-lineColor-base hover:bg-amber-400 hover:text-black" @click="handleClickCdn">打开支付</button>-->
<!--      </div>-->
      <div class="pb-10 mt-10">
        <sub-title title="当前支付token" :font-style="{ color: 'white' }" />
        <div class="my-5">{{ accessToken }}</div>
        <el-button type="primary" @click="handleShowDialog">测试支付</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { Message, Form, FormItem, Select, Switch, Option, Dialog, Button } from 'element-ui'
import tools from '~/utils'
import MEditor from '~/site/components/editor'
import SubTitle from '~/components/common/SubTitle'

const customParams = {
  "order_id": "123123",
  "country": "BR",
  "currency": "BRL",
  "amount": 150,
  "project_id": "86",
  "payer": {
    "address": {
      "city": "10213",
      "number": "123",
      "state": "1997",
      "street": "街道",
      "zip_code": "2201"
    },
    "device_id": "",
    "document": "22242048686",
    "email": "kognbage@163.com",
    "id": "VL74GAEB",
    "ip": "",
    "name": "namsfs",
    "phone": "18588545825",
    "user_reference": ""
  },
  "items": [
    {
      "id": "123663",
      "total_amount": 1.99,
      "goods": [
        {
          "id": "consequat dolore aute",
          "name": "consequat",
          "icon": "https://img0.baidu.com/it/u=3524086258,727431468&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
          "description": "consectetur",
          "amount": 1.99,
          "num": 1,
          "price": 1.99
        }
      ],
    }
  ],
  "settings": {
    "ui": {
      "theme":"default_dark",
      "global": {
        "padding": "0"
      }
    },
    "device": "desktop",
    "language": "cn",
    "show_order": false,
    "return_url": "https://kop.kopglobal.com/pay/payresult"
  },    "merchant_id": "16",
  "isTrusted": true
}

export default {
  components: {
    SubTitle,
    // ElInput: Input,
    ElForm: Form,
    ElFormItem: FormItem,
    ElSelect: Select,
    ElSwitch: Switch,
    ElOption: Option,
    ElDialog: Dialog,
    ElButton: Button,
    MEditor
    // xsolla
  },
  data() {
    return {
      PayStation: null,
      visible: false,
      countryOptions: [
        {
          label: 'US',
          value: 'US'
        },
        {
          label: 'HK',
          value: 'HK'
        },
        {
          label: 'BR',
          value: 'BR'
        },
        {
          label: 'IN',
          value: 'IN'
        }
      ],
      languageOptions: [
        {
          label: '简体中文',
          value: 'cn'
        },
        {
          label: '日语',
          value: 'ja'
        },
        {
          label: '英语',
          value: 'en'
        }
      ],
      deviceOptions: [
        {
          label: '桌面端',
          value: 'desktop'
        },
        {
          label: '移动端',
          value: 'mobile'
        }
      ],
      platformOptions: [
        {
          label: 'Dlocal',
          value: 'dlocal'
        },
        {
          label: 'Xsolla',
          value: 'xsolla'
        },
        {
          label: 'Mycard',
          value: 'mycard'
        }
      ],
      envOptions: [
        {
          label: '预发布--pre',
          value: 'pre'
        },
        {
          label: '测试--test',
          value: 'development'
        },
        {
          label: '正式-release',
          value: 'release'
        }
      ],
      currencyOptions: [
        {
          label: 'USD',
          value: 'USD'
        },
        {
          label: 'TWD',
          value: 'TWD'
        },
        {
          label: 'BRL',
          value: 'BRL'
        },
        {
          label: 'INR',
          value: 'INR'
        }
      ],
      form: {
        env: 'pre',
        debug: true,
        device: 'desktop',
        country: '',
        currency: '',
        sandbox: true,
        platform: '',
        language: 'cn'
      },
      rules: {
        platform: [
          { required: true, message: '请选择支付平台' }
        ],
        env: [
          { required: true, message: '请选择要测试的支付环境' }
        ],
        device: [
          { required: true, message: '请选择设备类型' }
        ],
        country: [
          { required: true, message: '请选择国家' }
        ],
        currency: [
          { required: true, message: '请选择货币' }
        ],
      },
      sandbox: true, // 是否沙盒模式
      accessToken: '',
      localCdn: 'http://127.0.0.1:3501/bundle.js',
      originCdn: `https://koppay-1252325870.cos.accelerate.myqcloud.com/payStation/dist/bundle.js?time=${new Date().getTime()}`,
      dlocalParams: customParams,
      queryParams: `${JSON.stringify(customParams, null, 4)}`
    }
  },
  mounted() {
  },
  methods: {
    async init() {
      await tools.loadJsAsync(this.form.debug ? this.localCdn : this.originCdn, true).catch(err => {
        Message.error(err)
      })
      Message.success('SDK加载成功')
    },
    async validForm() {
      await this.init()
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleSubmit()
        } else {
          Message.error('表单校验错误~，请修改配置后重试')
          return false
        }
      })
    },
    async handleSubmit() {
      await this.generateToken()
      this.handleCancel()
      this.openPay()
    },
    onPlatformChange(value) {
      const platformParamsMap = {
        'xsolla': {
          country: 'US',
          currency: 'USD'
        },
        'dlocal': {
          country: 'BR',
          currency: 'BRL'
        },
        'mycard': {
          country: 'HK',
          currency: 'TWD'
        }
      }
      const data = platformParamsMap[value]
      const origin = tools.mergeToObject(JSON.parse(this.queryParams), data)
      for (const key in platformParamsMap[value]) {
        this.form[key] = data[key]
      }
      this.queryParams = JSON.stringify(origin, null, 4)
    },
    handleShowDialog() {
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    openPay() {
      const { env, sandbox, debug } = this.form
      const that = this;
      const PayStation = new window.KopPay({
        accessToken: this.accessToken,
        env,
        sandbox,
        debug, // true: 打开本地koppay开发地址 false: 打开线上koppay地址
        lightbox: {
          width: 800,
          height: 600,
          spinner: 'puff',
          closeable: true
        },
        on: {
          load(e) {
            // eslint-disable-next-line no-console
            console.log('`SDK加载完成`', e)
          },
          payerUpdate(e) {
            console.log('payerUpdate', e)
            that.doPayerUpdate(e)
          },
          cardsUpdate(e) {
            console.log('cardsUpdate', e)
          },
          beforeClose() {
            return new Promise((resolve) => {
              const confirm = window.confirm('您确认要取消付款吗？')
              resolve(confirm)
            })
          }
        }
      })
      PayStation.open()
    },
    async generateToken(options) {
      const { sandbox, device, platform, language } = this.form
      let params = JSON.parse(this.queryParams)
      params.settings.device = device
      params.settings.language = language
      if (options) {
        params = Object.assign(params, options)
      }
      this.queryParams = JSON.stringify(params, null, 4)
      const api = sandbox ? this.$api.koppay.getTokenSandbox : this.$api.koppay.getToken
      const [err, res] = await this.$utils.to(api(params))
      if (!err) {
        Message.success(`平台：${platform}支付token获取成功`)
        this.accessToken = res.token
      }
    },
    handleClickCdn() {
      this.openPay()
    },
    countryChange(val) {
      const temp = JSON.parse(this.queryParams)
      temp.country = val
      this.queryParams = JSON.stringify(temp, null, 4)
    },
    currencyChange(val) {
      const temp = JSON.parse(this.queryParams)
      temp.currency = val
      this.queryParams = JSON.stringify(temp, null, 4)
    },
     doPayerUpdate (info) {
      const userInfo = this.$store.state.user.siteUserInfo;
      console.log('userInfo:', userInfo, info)
      // const [err, res] = await this.$utils.to(this.$api.siteBuild.updateUserInfos(params))
      // if (!err) {
      //   Message.success(`平台：${platform}支付token获取成功`)
      //   this.accessToken = res.token
      // }
    }
  }
}
</script>
