<template>
  <div class="flex items-center justify-center w-full h-full bg-white flex-col bg-primary-light">
    <color-model v-model="value" />
  </div>
</template>

<script>
import colorModel from "~/components/colorModel/index.vue"
export default {
  components: {
    colorModel
  },
  data() {
    return {
      value: '',
      list: [
        {
          label: '我的详情页',
          value: '我的详情页'
        },
        {
          label: '我的主页',
          value: '我的主页'
        }
      ]
    }
  }
}
</script>

<style lang="less">

</style>
