<template>
  <div>
    <menu-item :border-bottom="false" :label-size="colorLabelSize" :left-circle="colorCircle"
      class="menu-item-background" :label="backgroundName">
      <menu-fill v-if="!colorDisabled" :color-key="colorKey" class="block" :class="fillClass" :target="model" :target-key="targetKey" :eye-key="colorEyeKey" :eye="fillEye" @change="onColorChange"></menu-fill>
      <menuBackgroundImage v-if="!imageDisabled" :image-key="imageKey" :image-size-key="imageSizeKey"
        :image-opacity-key="imageOpacityKey" :eye-key="imageEyeKey" :suggestion="suggestion" :target="model"
        @image-change="$emit('image-change', $event)" @visible-change="$emit('image-visible-change', $event)"
        @opacity-change="$emit('image-opacity-change', $event)">
      </menuBackgroundImage>
    </menu-item>
    <menu-item v-if="!backgroundBlurDisabled" :label-size="blurLabelSize" :left-circle="blurCircle" :border-bottom="borderBottom" :label="backgroundBlurName">
      <div class="flex items-center" :class="sliderClass">
        <Slider v-model="model.backgroundBlur" class="w-full" target-key="backgroundBlur" @change="changeBackgroundBlur" />
      </div>
    </menu-item>
  </div>
</template>
<script>
import Vue from 'vue'
import MenuItem from '~/components/menu/menuItem'
import Slider from "~/components/menu/menuSlider";
import menuFill from '~/components/menu/menuFill.vue'
import menuBackgroundImage from '~/components/menu/menuBackgroundImage.vue'
export default {
  components: {
    MenuItem,
    menuFill,
    Slider,
    menuBackgroundImage,
  },
  props: {
    sliderClass: {
      type: String,
      default: 'pb-5'
    },
    fillClass: {
      type: String,
      default: 'mb-8px'
    },
    model: {
      type: Object,
      default() {
        return {}
      },
    },
    targetKey: {
      type: String,
      default: ''
    },
    colorDisabled: {
      type: Boolean,
      default: false,
    },
    imageDisabled: {
      type: Boolean,
      default: false,
    },
    backgroundBlurDisabled: {
      type: Boolean,
      default: false
    },
    backgroundBlurName: {
      type: String,
      default: () => {
        return Vue._my_i18n.t('menu.backgroundBlur')
      }
    },
    backgroundName: {
      type: String,
      default: () => {
        return Vue._my_i18n.t('menu.background')
      }
    },
    fillEye: {
      type: Boolean,
      default: true
    },
    labelSize: {
      type: String,
      default: ''
    },
    colorCircle: {
      type: Boolean,
      default: false
    },
    blurCircle: {
      type: Boolean,
      default: false
    },
    borderBottom: {
      type: Boolean,
      default: false
    },
    suggestion: {
      type: Object,
      default() {
        return null
      },
    },
    colorKey: {
      type: String,
      default: 'backgroundColor'
    },
    colorEyeKey: {
      type: String,
      default: 'backgroundColorShow'
    },
    imageKey: {
      type: String,
      default: 'backgroundImage',
    },
    imageSizeKey: {
      type: String,
      default: 'backgroundSize',
    },
    imageOpacityKey: {
      type: String,
      default: 'backgroundOpacity',
    },
    imageEyeKey: {
      type: String,
      default: 'backgroundImageShow',
    },
  },
  computed: {
    colorLabelSize() {
      if (this.labelSize) {
        return this.labelSize
      }
      if (this.colorCircle) {
        return 'small'
      }
      return 'normal'
    },
    blurLabelSize() {
      if (this.blurCircle) {
        return 'small'
      }
      return 'normal'
    }
  },
  methods: {
    onColorChange(value) {
      this.$emit('change', value)
    },
    changeBackgroundBlur(val) {
      if (this.model?.setBackgroundBlur) {
        this.model.setBackgroundBlur(+val)
      }
    }
  }
}
</script>
<style lang="less" scoped>

</style>
